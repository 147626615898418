import Vue from 'vue';  
import Vuex from 'vuex';  
  
Vue.use(Vuex);  
  
const store = new Vuex.Store({  
  state: {  
    // 在这里定义应用程序的状态  
    menuCollapsed: false, // 假设菜单的初始状态为未折叠  
  },  
  mutations: {  
    // 在这里定义 mutation 来更新应用程序的状态  
    collapseMenu(state) {  
      // 在这里可以通过 state 对象访问应用程序的状态  
      // 根据需要执行操作来更新状态  
      state.menuCollapsed = !state.menuCollapsed;  
    },  
  },  
  actions: {  
    // 在这里定义其他操作  
  },  
  getters: {  
    // 在这里定义可以获取状态的方法  
  },  
});
export default store