import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import JsEncrypt from 'jsencrypt'

import router from './router'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.prototype.$axios = axios
// Vue.prototype.URLhost = 'localhost:8081'
Vue.prototype.URLhost = 'https://yzulcy.top:8080'

new Vue({
  router,
  store,
  axios,

  render: h => h(App),

}).$mount('#app')