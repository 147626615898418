<template>
    <div class="login">
        <div class="box">
            <h2>智慧医疗</h2>
            <div class="form-and-keyboard-container">
                <el-form class="demo-ruleForm" status-icon :model="loginForm" label-width="100px" :rules="rules"
                    ref="loginForm">
                    <el-form-item prop="caseNo">
                        <i class="el-icon-caseNo"></i>
                        <el-input placeholder="请输入病历号" v-model="loginForm.caseNo" auto-complete="off"></el-input>
                        <!-- 将按钮移到表单内部 -->
                        <div class="input-buttons">
                            <el-button type="primary" @click="submitForm('loginForm')">提交</el-button>
                            <el-button @click="resetForm('loginForm')">重置</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <!-- 添加数字键盘 -->
                <div class="numeric-keyboard">
                    <div v-for="(row, index) in keyboardRows" :key="index" class="keyboard-row">
                        <button v-for="num in row" :key="num" @click="appendNumber(num)">{{ num }}</button>
                    </div>
                    <div class="keyboard-row">
                        <button @click="backspace">删除</button>
                        <button @click="clearInput">清空</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MedicalLoginForm',
    props: ['medicalRecordNumber'],
    data() {
        return {
            loginForm: {
                caseNo: this.medicalRecordNumber || ''
            },
            rules: {
                caseNo: [
                    { required: true, message: '请输入病历号', trigger: 'blur' }
                ]
            },
            // 定义数字键盘的行
            keyboardRows: [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
                [0]
            ]
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    localStorage.setItem('caseNo', this.loginForm.caseNo);
                    console.log('提交信息:', this.loginForm.caseNo);
                    this.$router.push({
                        name: "HRSPO2"
                    });
                } else {
                    console.log('错误提交!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        appendNumber(number) {
            this.loginForm.caseNo += number;
        },
        backspace() {
            this.loginForm.caseNo = this.loginForm.caseNo.slice(0, -1);
        },
        clearInput() {
            this.loginForm.caseNo = '';
        }
    }
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/image/login.jpg");
  position: fixed;
  background-size: cover;
}

.box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.34);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.box h2 {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  line-height: 30px;
  color: #3333aa;
  margin-bottom: 20px;
}

.form-and-keyboard-container {
  display: flex;
  gap: 20px;
  align-items: flex-start; /* 对齐顶部 */
}

.el-form .el-form-item i {
  width: 50px;
  color: #3333aa;
}

.el-form .el-form-item .el-input {
  width: 350px; /* 增大输入框宽度 */

  font-size: 18px; /* 可以适当增大字体大小 */
}

.input-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px; /* 在输入框和按钮之间添加一些间距 */
}

.input-buttons .el-button {
  background-color: #3333aa;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 18px;
  margin-left: 50px;
}

.numeric-keyboard {
  display: grid;
  gap: 10px;
  width: 300px; /* 将键盘宽度翻倍 */
}

.keyboard-row {
  display: flex;
  justify-content: space-between;
}

.keyboard-row button {
  width: 96px; /* 将按键宽度翻倍 */
  height: 96px; /* 将按键高度翻倍 */
  font-size: 40px; /* 将字体大小翻倍 */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.keyboard-row button:hover {
  background-color: #e0e0e0;
}
</style>