<template>
     <div id="app">
    <!-- 使用路由占位符引入路由 -->
    <router-view></router-view>
  </div>

</template>

<script>
    export default {
        name:'App'
    }


</script>

<style>
    .el-table .cell {
        /*text-align: center;*/
        white-space: pre-line; /*保留换行符*/
    }
    #app {
        height: 100vh;
    }

    html, body {
        margin: 0;
        padding: 0;
    }
</style>